<template>
  <div :for="name" class="option">
    <ValidationProvider
      tag="div"
      :rules="rules"
      :label="label"
      v-slot="{ errors }"
    >


      <div role="group" class="custom-control custom-radio">
        <input
          :id="name"
          type="radio"
          class="custom-control-input"
          :disabled="disabled"
          v-model="selValueModel.code"
          :value="value"
          :name="name"
          v-on:input="emitValue"
        />
        <label :for="name" class="custom-control-label"> {{ label }} </label>
        <label :for="name" class="click"></label>
      </div>
      <div v-if="errors && errors[0]" class="invalid-feedback icon">
        <div v-if="val == '1'">
          <span class="center">{{ errors[0] }}</span>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "cRadioButton",
  model: {
    prop: "selValue",
  },
  watch: {
    selValue(val) {
      if (val !== this.selValueModel) {
        this.selValueModel = val;
      }
    },
  },
  methods: {
    emitValue() {
      this.$emit("input", this.selValueModel);
    },
  },
  computed: {
    // selectedValue: {
    //     get: function() {
    //         return this.value
    //     },
    //     set: function() {
    //         // Communicate the change to parent component so that selectedValue can be updated
    //         this.$emit("input", this.selectedValue)
    //     }
    // }
  },
  components: {
    ValidationProvider,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    val: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: "",
    },
    name: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    selValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Array,
      default: null,
    },
  },

  data: () => ({
    selValueModel: null,
  }),
  created() {
    //    alert(JSON.stringify(this.selValue))
    this.selValueModel = this.selValue;
  },
};
</script>
<style scoped>
.invalid-feedback {
  display: block;
}
.radio-center {
  padding-left: 50px;
}
</style>
