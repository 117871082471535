<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="{ errors }">
    <CRow>
      <CCol col="12">
        <label for="name">{{label}}</label>
        <textarea
          :type="type"
          class="form-control"
          id="name"
          v-model="currentValue"
          :placeholder="placeholder"
        />
        <div v-if="errors && errors[0]" v-getError="emitError(id!=null?id:placeholder)" class="invalid-feedback icon">
          <span>{{ errors[0] }}</span>
        </div>
      </CCol>
    </CRow>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "cMultiLineText",
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    rules: {
      type: [String, Object],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    vid: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    toEmit:{
      type: Boolean,
      default: false
    },
    id:{
       type: String|Object,
      default: null
    },
  },
  data: () => ({
    currentValue: null
  }),
  watch: {
    currentValue(value) {
      this.$emit("input", value);
    }
  },
    directives: {
  getError: {
    // directive definition
    inserted: function (el) {
      el.focus()
    }
  },
  },
  created() {
    if (this.value) {
      this.currentValue = this.value;
    }
     if(this.toEmit){
      this.$on('emitError', this.emitError /*the name of the method to call */)
   }
  },
  methods: {
    emitError(pName){
                this.$emit('caughtError',pName);
     
            },
  }
};
</script>
