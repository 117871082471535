<template>
  <div>
    <CCard>
      <ValidationObserver v-slot="{ handleSubmit }">
        <CForm autocomplete="off">
          <CCardHeader class="grey-header">
            <CRow class="flex-flow-column">
              <CCol>
                <strong>{{ "Notification" }}</strong>
              </CCol>

              <p class="desc">
                {{ "Notification Setup" }}
              </p>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="7">
                <label><strong>TITLE</strong></label>
                <cTextInputComponent
                  v-model="notification.title"
                  :placeholder="'Enter Title'"
                  :name="'title'"
                  rules="required"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
                <label><strong>PUSH MESSAGE</strong></label>
                <cMultiLineText
                  v-model="notification.message"
                  :name="'Message'"
                  rules="required"
                />
                <label><strong>ACTION</strong></label>
                <CRow class="mb-20px">
                  <cRadioButton
                    v-for="Item in actionTypes"
                    class="radio-style app-radio"
                    :key="Item.code"
                    :val="Item.value"
                    :selValue="notification.action"
                    :value="Item.code"
                    :name="Item.name"
                    :label="Item.name"
                    v-on:input="onChangeAction(Item)"
                  />
                </CRow>
                <cTextInputComponent
                  v-if="
                    notification &&
                    notification.action &&
                    notification.action.code == 'url'"
                  v-model="notification.actionUrl"
                  :placeholder="''"
                  :label="''"
                  :name="''"
                  rules="required"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
                <label><strong>PLATFORM</strong></label>
            
                 <ValidationProvider :rules="'required'" v-slot="{ errors }">
                <CRow class="mb-20px" v-model="platformObj">
                  
                  <div class="radio-style"
                    v-for="(type, index) in platformTypes"
                    :key="index + 'platformTypes'"
                  >
                    <label class="pb-3">
                      <input
                        @input="onChangePlatform(type)"
                        type="checkbox"
                        :checked="type.isSelected"
                      />
                      {{ type.name }}
                    </label>
                  </div>
                   <div v-if="errors && errors[0]" class="invalid-feedback icon">
                                <span>{{ errors[0] }}</span>
                            </div>
                  
                </CRow>
                 </ValidationProvider>
              </CCol>
              <CCol>
                <label><strong>IMAGE</strong></label>
                <div>
                <file-pond
                  @addfile="getImages"
                  name="test"
                  ref="reference"
                  :label-idle="filepondLabel"
                  :allow-multiple="false"
                  :allowBrowse="true"
                  :accepted-file-types="'image/jpeg, image/png'"
                  :allowImagePreview="true"
                  :allowImageTransform="true"
                />
                </div>
                <div v-if="isUserUpdate && notification.imageUrl">
                  <span v-if="notification.imageUrl" @click="notification.imageUrl=null" id="close">&times;</span>
                    <img v-if="isValidImage" width="60" height="60" :src="notification.imageUrl" @error="ImgError"/>
                   <img v-if="!isValidImage" width="60" height="60" src="@imgPath/img/png/defaultImg.png" />
                </div>
                <label><strong>SCHEDULE</strong></label>
                <CRow class="mb-20px">
                  <cRadioButton
                    v-for="Item in scheduleTypes"
                    class="radio-style app-radio"
                    :key="Item.code"
                    :val="Item.value"
                    :selValue="notification.schedule"
                    :value="Item.code"
                    :name="Item.name"
                    :label="Item.name"
                    v-on:input="onChangeSchedule(Item)"
                  />
                </CRow>
                <CRow v-if="notification.schedule.code=='later'">
                  <CCol>
                    <label><strong>DATE</strong></label>
                     <ValidationProvider :rules="'required'" v-slot="{ errors }">
                     
                       <CRow class="mb-20px" v-model="notification.date">
                     <HotelDatePicker
                             :closeDatepickerOnClickOutside="true"
                             class="center"
                             :id="'datePicker'"
                             :ref="'datePicker'"
                             :startDate="new Date()"
                             :singleDaySelection="true"
                             :format="DATE_TIME_FORMAT.DEFAULT_DATE_FORMAT"
                             :minNights="1"
                             :i18n="assigni18nObjByLang(!isUserUpdate?new Date():(notification.date?notification.date:null))"
                             :enableCheckout="false"
                             :showYear="true"
                             :endDate='new Date("2022-12-31")'
                             :displayClearButton="false"
                             :hoveringTooltip="false"
                             @check-in-changed="(val)=>{notification.date=val}"
            ></HotelDatePicker>
             <div v-if="errors && errors[0]" class="invalid-feedback icon">
                                <span>{{ errors[0] }}</span>
                            </div>
                       </CRow>
            </ValidationProvider>
                  </CCol>
                  <CCol>
                    <label><strong>TIME</strong></label>
                      <ValidationProvider :rules="'required'" v-slot="{ errors }">
                       <CRow class="mb-20px" v-model="notification.time">
                    <vue-timepicker
                      ref="arrTimePick"
                      class="input-normal w-100"
                      v-model="notification.time"
                      
                      :format="DATE_TIME_FORMAT.DEFAULT_TIME_FORMAT"
                      :minute-interval="5"
                      close-on-complete
                      hide-clear-button
                      @open="toggleTimePickAnimClass('arrTimePick')"
                      @close="toggleTimePickAnimClass('arrTimePick')"
                    />
                    <div v-if="errors && errors[0]" class="invalid-feedback icon">
                                <span>{{ errors[0] }}</span>
                            </div>
                       </CRow>
                        </ValidationProvider>
                  </CCol>
                </CRow>

                 <!-- <div class="radio-style"
                    v-for="(type, index) in platformTypes"
                    :key="index + 'platformTypes'"
                  > -->
                    <label class="pb-3">
                      <input
                        @input="isTestOnDevice=!isTestOnDevice"
                        type="checkbox"
                        :checked="isTestOnDevice"
                      />
                      Test on Device
                    </label>
                  <!-- </div> -->
                 <cTextInputComponent
                  v-if="isTestOnDevice"
                  v-model="notification.deviceId"
                  :placeholder="''"
                  :label="'Device ID'"
                  :name="'Device ID'"
                  rules="required"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
                <CRow>
                  <CCol>
                    <CButton
               type="submit"
                color="danger"
                class="action-c-btn"
                @click.prevent="handleSubmit(onDrafted)"
              >
                {{ 'SAVE DRAFT' }}
              </CButton>
                  </CCol>
                  <CCol>
                     <CButton
                col="12"
                type="submit"
                color="success removewrap"
                class="action-c-btn"
                @click.prevent="handleSubmit(onAddUserFormSubmit)"
              >
                <img src="@imgPath/img/svg/icons/correct.svg" />
                {{'SUBMIT'}}
              </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>


          </CCardBody>

        </CForm>
      </ValidationObserver>
    </CCard>
    <cAlertModal
      v-if="showAlertModal"
      :buttonList="buttonList"
      :status="modalStatus"
      :show="showAlertModal"
      :desc="confirmText"
    ></cAlertModal>
  </div>
</template>

<script>
import BaseConfiguration from "../../config/base";
import cDropDown from "@/components/form/cDropDown";
import { goBack, updateDataEdited } from "@/router/index";
import { ValidationObserver } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import cTextInputComponent from "@/components/form/cTextInputComponent";
import cAlertModal from "../../components/form/cAlertModal.vue";
import {
  COMPONENT_NAME,
  ALERT_MODAL_STATUS,
  DATE_TIME_FORMAT,
  NOTIFICATION_ACTION,
  NOTIFICATION_SCHEDULE,
  NOTIFICATION_TYPE,
  NOTIFICATION_PLATFORMS
} from "../../util/constants";
import ApiCreateNotification from "../../services/notificaion/apiCreateNotification";
import ApiUpdateNotification from "../../services/notificaion/apiUpdateNotification" 
import ApiUpdateDiyPostDetails from "../../services/diy/apiUpdatepost";
import ApiDiyPostDetailsById from "../../services/diy/apiGetpostDetailsByid";
import Logger from "../../services/logger";
import { FILE_NAME, LOG_TYPE } from "../../config/API_DATA";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
  VALIDATE_GL_SUCCESS_RES,
} from "../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
} from "../../util/util";
import cMultiLineText from "../../components/form/cMultiLineText";
import cRichTextEditor from "../../components/form/cRichTextEditor";
import CRadioButton from "../../components/form/cRadioButton.vue";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import HotelDatePicker from "vue-range-datechooser";
import Vue from 'vue';
    import {formatDate} from "../../util/date/dateFunctions";
    import {
        ValidationProvider
    } from "vee-validate";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);
export default {
  name: COMPONENT_NAME.ADD_USER,
  components: {
    VueTimepicker,
    FilePond,
    ValidationObserver,
    cTextInputComponent,
    cMultiLineText,
    cRichTextEditor,
    cAlertModal,
    cDropDown,
    CRadioButton,
    HotelDatePicker,
    ValidationProvider,
  },

  data: () => {
    return {
      notification: {
        title: null,
        message: null,
        action: null,
        actionUrl: null,
        schedule: null,
        time: null,
        date: null,
        imageUrl: null,
        platforms: [],
        countryCode: null,
        type: null,
        isDraft: false,
        deviceId: null
      },
      platformObj:null,
      isTestOnDevice: false,
      filepondLabel:
        'Drop files here or <span class="filepond--label-action">Browse</span>',
      scheduleTypes: null,
      actionTypes: null,
      platformTypes: null,
      isUserUpdate: false,
      alertValue: null,
      showAlertModal: false,
      modalStatus: null,
      buttonList: [],
      confirmText: null,
       isValidImage:true,
    };
  },

  methods: {
    ...mapActions("sAddDiyPost", {
      SET_CREATE_POST_RESPONSE: "SET_CREATE_POST_RESPONSE",
      SET_CREATE_USER_REQUEST: "SET_CREATE_POST_REQUEST",
      SET_UPDATE_POST_RESPONSE: "SET_UPDATE_POST_RESPONSE",
      SET_UPDATE_POST_REQUEST: "SET_UPDATE_POST_REQUEST",
      CLEAR_ADD_POST_STORE: "CLEAR_ADD_POST_STORE",
    }),
    ...mapActions("sManageDiyPost", {
      CLEAR_MANAGE_POST_STORE: "CLEAR_MANAGE_POST_STORE",
    }),

     ImgError(){
            this.isValidImage = false;
            // this.defaultImgSrc = '@imgPath/img/png/defaultImg.png'
        },
         

          assigni18nObjByLang(pCurrDueDate) {
                let retValue;
                try {
                    if (BaseConfiguration.isDebug) console.log("assigni18nObjByLang");
                    // this.notification.date=formatDate(pCurrDueDate, DATE_TIME_FORMAT.DEFAULT_DATE_FORMAT)
                    retValue = {
                        night: this.$t("flightDatePicker.night"),
                        nights: this.$t("flightDatePicker.nights"),
                        "day-names": [
                            this.$t("flightDatePicker.sun"),
                            this.$t("flightDatePicker.mon"),
                            this.$t("flightDatePicker.tue"),
                            this.$t("flightDatePicker.wed"),
                            this.$t("flightDatePicker.thur"),
                            this.$t("flightDatePicker.fri"),
                            this.$t("flightDatePicker.sat")
                        ],
                        "check-in": pCurrDueDate
                            ? formatDate(pCurrDueDate, DATE_TIME_FORMAT.DEFAULT_DATE_FORMAT)
                            : null,
                        "check-out": this.$t("flightDatePicker.checkOut"),
                        "month-names": [
                            this.$t("flightDatePicker.jan"),
                            this.$t("flightDatePicker.feb"),
                            this.$t("flightDatePicker.mar"),
                            this.$t("flightDatePicker.apr"),
                            this.$t("flightDatePicker.may"),
                            this.$t("flightDatePicker.jun"),
                            this.$t("flightDatePicker.july"),
                            this.$t("flightDatePicker.aug"),
                            this.$t("flightDatePicker.sep"),
                            this.$t("flightDatePicker.oct"),
                            this.$t("flightDatePicker.nov"),
                            this.$t("flightDatePicker.dec")
                        ]
                    };
                } catch (err) {
                    let body = Logger.buildRequest(
                        FILE_NAME.C_EDIT_FLIGHT,
                        "error in assigni18nObjByLang",
                        err.toString(),
                        "assigni18nObjByLang",
                        LOG_TYPE.ERROR
                    );
                    Logger.getResponse(body);
                }
                return retValue;
            },

    toggleTimePickAnimClass(pRef) {
      try {
        if (BaseConfiguration.isDebug) console.log("toggleTimePickAnimClass");
        if (
          this.$refs &&
          this.$refs[pRef] &&
          this.$refs[pRef].$el &&
          this.$refs[pRef].$el.childNodes
        ) {
          for (let i = 0; i < this.$refs[pRef].$el.childNodes.length; i++) {
            if (
              this.$refs[pRef].$el.childNodes[i].className &&
              this.$refs[pRef].$el.childNodes[i].className.includes("dropdown")
            ) {
              if (
                this.$refs[pRef].$el.childNodes[i].classList.contains("active")
              ) {
                this.$refs[pRef].$el.childNodes[i].classList.remove("active");
              } else {
                this.$refs[pRef].$el.childNodes[i].classList.add("active");
              }
            }
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.SCHEDULE_NEW,
          "error in toggleTimePickAnimClass",
          err.toString(),
          "toggleTimePickAnimClass",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getImages(error, file) {
      // if(file.getFileEncodeDataURL().substring(23,29)!='null'){
      this.notification.imageUrl = file.getFileEncodeDataURL();
      
      this.isValidImage = null;

            Vue.nextTick(() => {
              ///for a smooth update
              this.isValidImage = true;
            });
    },

    onEdit() {
      try {
        if (BaseConfiguration.isDebug) console.log("onEdit");
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onEdit",
          err.toString(),
          "onEdit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onChangeAction(pSelectedAction) {
      try {
        if (BaseConfiguration.isDebug) console.log("onChangeAction");
        this.notification.action = pSelectedAction;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onChangeAction",
          err.toString(),
          "onChangeAction",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onChangeSchedule(pSelectedSchedule) {
      try {
        if (BaseConfiguration.isDebug) console.log("onChangeSchedule");
        this.notification.schedule = pSelectedSchedule;
        if(this.notification.schedule.code == NOTIFICATION_SCHEDULE.NOW){
          this.notification.date =null;
          this.notification.time = null;
        }else{
           this.notification.date = this.notification.date?this.notification.date:null;
          this.notification.time = this.notification.time?this.notification.time:{ HH: "01", mm: "00" };
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onChangeSchedule",
          err.toString(),
          "onChangeSchedule",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onChangePlatform(pSelectedAction) {
      try {
        if (BaseConfiguration.isDebug) console.log("onChangePlatform");
        this.platformTypes.filter((res) => {
          if (res.name == pSelectedAction.name) {
            res.isSelected = true;
          }
        });
        this.notification.platforms = [];
        this.platformTypes.filter((res) => {
          if (res.isSelected) {
            this.notification.platforms.push(res);
          }
          
        });
        if(this.notification.platforms.length!=0){
          this.platformObj='platform'
        }else{
          this.platformObj=null
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onChangePlatform",
          err.toString(),
          "onChangePlatform",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getPlatformTypes() {
      try {
        if (BaseConfiguration.isDebug) console.log("getPlatformTypes");
        this.platformTypes = [
          {
            name: "Android",
            code: NOTIFICATION_PLATFORMS.ANDROID,
            isSelected: false,
          },
          {
            name: "IOS",
            code: NOTIFICATION_PLATFORMS.IOS,
            isSelected: false,
          },
          {
            name: "Chrome",
            code: NOTIFICATION_PLATFORMS.WEB,
            isSelected: false,
          },
        ];
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in getPlatformTypes",
          err.toString(),
          "getPlatformTypes",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getScheduleTypes() {
      try {
        if (BaseConfiguration.isDebug) console.log("getScheduleTypes");
        this.scheduleTypes = [
          {
            name: "Send Now",
            code: NOTIFICATION_SCHEDULE.NOW,
            value: NOTIFICATION_SCHEDULE.NOW,
          },
          {
            name: "Send Later",
            code: NOTIFICATION_SCHEDULE.LATER,
            value: NOTIFICATION_SCHEDULE.LATER,
          },
        ];
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in getScheduleTypes",
          err.toString(),
          "getScheduleTypes",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getActionTypes() {
      try {
        if (BaseConfiguration.isDebug) console.log("getActionTypes");
        this.actionTypes = [
          {
            name: "Open App",
            code: NOTIFICATION_ACTION.APP,
            value: NOTIFICATION_ACTION.APP,
          },
          {
            name: "URL",
            code: NOTIFICATION_ACTION.URL,
            value: NOTIFICATION_ACTION.URL,
          },
        ];
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in getActionTypes",
          err.toString(),
          "getActionTypes",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

  getNotificationTypes() {
      try {
        if (BaseConfiguration.isDebug) console.log("getNotificationTypes");
        this.actionTypes = [
          {
            name: "Once",
            code: NOTIFICATION_TYPE.ONCE,
            value: NOTIFICATION_TYPE.ONCE,
          },
          {
            name: "Repeated",
            code: NOTIFICATION_TYPE.REPEATED,
            value: NOTIFICATION_TYPE.REPEATED,
          },
        ];
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in getNotificationTypes",
          err.toString(),
          "getNotificationTypes",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    

    getModalBtnLs() {
      return [
        {
          name: this.$t("addNotification.ok"),
          func: this.navigateToAllUsers,
          color: "success",
        },
      ];
    },
   

    showAlert(value) {
      this.alertValue = value;
    },

    toggleAlertModal() {
      this.showAlertModal = !this.showAlertModal;
    },

    showModal(pStatus) {
      try {
        // let retValue;
        this.showAlertModal = true;
        if (pStatus) {
          this.modalStatus = pStatus;
        } else {
          this.modalStatus = this.isUserUpdate
            ? ALERT_MODAL_STATUS.INFO
            : ALERT_MODAL_STATUS.SUCCESS;
        }
        let desc = null;
        if (this.modalStatus == ALERT_MODAL_STATUS.INFO) {
          this.buttonList = [
            {
              name: 'No',
              func: this.toggleAlertModal,
              color: "danger",
            },
            {
              name: 'Yes',
              func: this.onCreateUserFormSubmit,
              color: "success",
            },
          ];
          desc ='Are you sure you want to save '+ this.notification.title+' ?';
        } else if (this.modalStatus == ALERT_MODAL_STATUS.SUCCESS) {
          this.buttonList = [
            {
              name: 'Ok',
              func: this.navigateToAllUsers,
              color: "danger",
            },
          ];
          desc = this.isUserUpdate
            ? this.$t("addNotification.updateSuccess", {
                fname: this.notification.title,
              })
            : this.$t("addNotification.addSuccess", {
                fname: this.notification.title,
              });
        }
        this.confirmText = desc;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in showModal",
          err.toString(),
          "showModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

    onDrafted() {
      try {
       this.notification.isDraft = true;
       this.showModal(ALERT_MODAL_STATUS.INFO);
      // this.onAddUserFormSubmit();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onDrafted",
          err.toString(),
          "onDrafted",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async onAddUserFormSubmit() {
      try {
        this.showModal(ALERT_MODAL_STATUS.INFO);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  on Add Post Form Submit",
          err.toString(),
          "onAddUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async onCreateUserFormSubmit() {
      try {
        if (this.isUserUpdate) {
          let updateNotificationStatus = await this.updateNotification(this.notification);
          if (updateNotificationStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS,'Notification is updated successfully!');
        } else {
          let createNotificationStatus = await this.createNotification(this.notification);
          if (createNotificationStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS,'Notification is added successfully!');
        }
        updateDataEdited(false);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onCreateUserFormSubmit",
          err.toString(),
          "onCreateUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

     async updateNotification(pUser) {
      let returnValue;

      try {
        this.showAlertModal = false;
        let reqObj = this.validateAndGetPostReqObj(pUser,true);
        let createUserRequest = ApiUpdateNotification.buildRequest(reqObj);

        if (createUserRequest != null) {
          // this.SET_CREATE_USER_REQUEST(createUserRequest);

          let createUserResponse = await ApiUpdateNotification.getResponse(
            createUserRequest
          );
          if (VALIDATE_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
            // this.SET_CREATE_POST_RESPONSE(returnValue);
            // this.clearAllUserRes();
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in updateNotification",
          err.toString(),
          "updateNotification()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    async createNotification(pUser) {
      let returnValue;

      try {
        this.showAlertModal = false;
        let reqObj = this.validateAndGetPostReqObj(pUser,false);
        let createUserRequest = ApiCreateNotification.buildRequest(reqObj);

        if (createUserRequest != null) {
        
          let createUserResponse = await ApiCreateNotification.getResponse(
            createUserRequest
          );
          if (VALIDATE_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
           
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Create post Status",
          err.toString(),
          "createNotification()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    clearAllUserRes() {
      this.CLEAR_ADD_POST_STORE(null);
      this.CLEAR_MANAGE_POST_STORE(null);
    },

    validateAndGetPostReqObj(pNotification,isUpdate) {
      let platforms = [];
      let time=pNotification.time?pNotification.time.HH+':'+pNotification.time.mm:null;
      pNotification.platforms.filter(platform => {
        platforms.push(platform.code)
      })
      let returnValue;
      if(isUpdate) {
      returnValue = {
        id: Number(pNotification.id),
         title: pNotification.title,
            message: pNotification.message,
            action: pNotification.action.code,
            schedule: pNotification.schedule.code,
            time: time,
            date: pNotification.schedule.code==NOTIFICATION_SCHEDULE.LATER?pNotification.date:null,
            imageUrl: pNotification.imageUrl,
            platforms: platforms,
            countryCode: pNotification.countryCode,
            isDraft: pNotification.isDraft,
            type: 'once',
            deviceId: pNotification.deviceId?pNotification.deviceId:null
      };
      }else{
         returnValue = {
       
         title: pNotification.title,
            message: pNotification.message,
            action: pNotification.action.code,
            schedule: pNotification.schedule.code,
            time: pNotification.time,
            date: pNotification.schedule.code==NOTIFICATION_SCHEDULE.LATER?pNotification.date:null,
            imageUrl: pNotification.imageUrl,
            platforms: platforms,
            countryCode: pNotification.countryCode,
            type: pNotification.type?pNotification.type:'once',
            isDraft: pNotification.isDraft,
            deviceId: pNotification.deviceId?pNotification.deviceId:null
      };
      }
      return returnValue;
    },

 
    navigateToAllUsers() {
      try {
        goBack();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  navigate To All Users",
          err.toString(),
          "navigate To All Users"
        );
        Logger.getResponse(body);
      }
    },
    async getPostDetailsById(id) {
      let returnValue;
      try {
        let getAllDiyPostRequest = ApiDiyPostDetailsById.buildRequest(id);

        if (getAllDiyPostRequest != null) {
          let allPostResponse = await ApiDiyPostDetailsById.getResponse(
            getAllDiyPostRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(allPostResponse)) {
            returnValue = allPostResponse.data.diy_project_project[0];
          } else {
            let error = getErrorMessage(allPostResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  getPostDetailsById",
          err.toString(),
          "getPostDetailsById"
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },
  },

  computed: {
    ...mapGetters("sAddDiyPost", {
      selectedPostObj: "GET_SELECTED_POST",
    }),
 ...mapGetters("sDiyNotification", {
      GET_SELECTED_NOTIFICATION: "GET_SELECTED_NOTIFICATION",
    }),
    ...mapGetters("sSysLogin", { adminSignIn: "GET_ADMIN_SIGNIN_RES" }),

    DATE_TIME_FORMAT() {
      return DATE_TIME_FORMAT;
    },
  },

  async created() {
    try {
      this.getPlatformTypes();
      this.getActionTypes();
      this.getScheduleTypes();
      this.notification.action = this.actionTypes[0];
      this.notification.schedule = this.scheduleTypes[0];
      
      if (this.GET_SELECTED_NOTIFICATION != null && this.GET_SELECTED_NOTIFICATION.id) {
        // let notification = await this.getPostDetailsById(this.selectedPostObj.id);
        let notification = this.GET_SELECTED_NOTIFICATION;          
        if (notification) {
          this.isUserUpdate = true;
          let schedule;
          let action;
          let platforms=[];
          this.scheduleTypes.filter(sch => {
            if(sch.code==notification.schedule){
              schedule = sch;
            }
          })
           this.actionTypes.filter(act => {
            if(act.code==notification.action){
              action = act;
            }
          })
          this.platformTypes.filter(plat => {
            notification.platforms.filter(selPlat => {
              if(selPlat=='android'){
                selPlat='Android'
              }
             if(plat.code==selPlat){
               plat.isSelected = true
              platforms.push(plat);
            }
           })
          })
          this.notification = {
            title: notification.title,
            message: notification.message,
            action: action,
            schedule: schedule,
            time: notification.time?notification.time:null,
            date: notification.date?notification.date:null,
            imageUrl: notification.imageUrl,
            platforms: platforms,
            countryCode: notification.countryCode,
            type: notification.type,
            isDraft: notification.status == 'draft'?true:false,
            id: notification.id,
          };
         
        } else {
          this.isUserUpdate = false;
        }
      } else {
        this.notification.date=new Date()
        this.isUserUpdate = false;
      }
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.ADD_USER,
        "error in  Created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );

      Logger.getResponse(body);
    }
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.ADD_USER);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>

<style>
.input {
  border-radius: 30px;

  width: 300px;
  height: 30px;
}
</style>
