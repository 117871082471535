import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import HttpPost from "../../http/httppost";

export default class ApiCreateNotification {
    static buildRequest(pReq) {
        let returnValue = null;
        try {
            let body = pReq;
            returnValue = body;
        }
        catch (err)
        {
            let body = Logger.buildRequest( FILE_NAME.API_CREATE_NOTIFICATIONS , "error in building body for Signin api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.GET_CREATE_DIY_NOTIFICATION, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_NOTIFICATIONS, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}